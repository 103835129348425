import {renderRichText, storyblokEditable} from '@storyblok/react'
import {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import LogoutItem from './Navigation/LogoutItem'
import {MaintenancePageStoryblok} from 'types/storyblok-types'

export type MaintenancePropsTypes = {
  blok: MaintenancePageStoryblok
}

const Maintenance = ({blok}: MaintenancePropsTypes) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const modalContent = (
    <div
      className="fixed left-0 top-0 z-30 h-screen w-screen bg-dats-secondary-5/40"
      {...storyblokEditable(blok)}
    >
      <div className="min-w-screen and relative min-h-screen backdrop-blur-sm">
        <div className="absolute left-1/2 top-1/2 h-1/2 w-[92%] -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-dats-accent-1 p-4 lg:min-h-[50%] lg:w-auto lg:min-w-[50%] lg:p-8">
          <div className="flex h-full flex-col items-start justify-around px-4 lg:px-16">
            <div className="">
              <h2 className="text-h1">{blok.title}</h2>
              {blok.text ? (
                <div
                  id="richtext"
                  className={`py-4 [overflow-wrap:anywhere]`}
                  dangerouslySetInnerHTML={{__html: renderRichText(blok.text)}}
                />
              ) : null}
              <div className="flex w-auto items-center justify-start">
                <LogoutItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById('modal-root')!,
    )
  } else {
    return null
  }
}

export default Maintenance
