import {FC} from 'react'
import MainMenuHeader from './MainMenuHeader'
import {MainMenuItemStoryblok, MainMenuStoryblok} from 'types/storyblok-types'
import {ISbStoryData} from '@storyblok/react'
import {MainMenuSlugAlternates} from 'mainMenuSlugTranslations'
import {TranslatedSlug} from 'types/types'
import MainMenu, {MenuItem} from './MainMenu'

type CommonProps = {
  isMainMenuVisible: boolean
  setIsMainMenuVisible: (value: boolean) => void
  mainMenuSlugAlternates: MainMenuSlugAlternates
  defaultSlug: string
  translatedSlugs: TranslatedSlug[]
}

type NavigationContainerProps = CommonProps & {
  mainMenu: ISbStoryData
}

type NavigationProps = CommonProps & {
  logoSrc: string | undefined
  menuItems: MenuItem[]
}

const Navigation: FC<NavigationProps> = ({
  setIsMainMenuVisible,
  isMainMenuVisible,
  logoSrc,
  ...props
}) => {
  return (
    <aside className="sticky top-0 z-10 size-full md:min-w-[200px] md:max-w-[200px]">
      <nav className="md:hidden">
        {isMainMenuVisible ? (
          <MainMenu
            logoSrc={logoSrc}
            shouldCloseMenuCallback={() => setIsMainMenuVisible(false)}
            {...props}
          />
        ) : (
          <MainMenuHeader
            logoSrc={logoSrc}
            isMainMenuVisible={isMainMenuVisible}
            shouldOpenMenuCallback={() => setIsMainMenuVisible(true)}
          />
        )}
      </nav>
      <nav className="hidden md:block">
        <MainMenu logoSrc={logoSrc} {...props} />
      </nav>
    </aside>
  )
}

export {Navigation}

const NavigationContainer: FC<NavigationContainerProps> = ({
  mainMenu,
  ...props
}) => {
  let menuItems: MenuItem[] = []
  if (mainMenu.content.main_menu_item) {
    menuItems = (mainMenu.content.main_menu_item as MainMenuStoryblok)
      ?.filter((item: any) => item.component === 'main_menu_item')
      ?.map((item: MainMenuItemStoryblok) => ({
        uuid: item._uid,
        cachedUrl: item.url?.cached_url,
        url: item.url?.cached_url,
        iconAlt: item.icon?.alt,
        iconSrc: item.icon?.filename,
        label: item.label,
      }))
  }

  return (
    <Navigation
      logoSrc={(mainMenu?.content as MainMenuStoryblok)?.logo?.filename}
      menuItems={menuItems}
      {...props}
    />
  )
}

export default NavigationContainer
