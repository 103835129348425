import React, {FC} from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import Icon from './icons/Icon'
import {IconName} from './icons/names'
import {AlertStoryblok} from 'types/storyblok-types'
import {storyblokEditable} from '@storyblok/react'

type AlertType = 'informative' | 'warning' | 'error'

type Props = {
  type: AlertType
  message: string
  cta?: string
  imageUrl?: string
  showClose?: boolean
  onClickClose?: () => void
  blok?: AlertStoryblok
}

const Alert: FC<Props> = ({
  type,
  imageUrl,
  message,
  cta,
  showClose,
  onClickClose,
  blok,
}) => {
  const ContentTag = cta
    ? (`a` as keyof JSX.IntrinsicElements)
    : (`div` as keyof JSX.IntrinsicElements)

  const iconColor = classNames('shrink-0', {
    'text-dats-secondary-3': type === 'informative',
    'text-dats-warning': type === 'warning',
    'text-dats-error': type === 'error',
  })

  return (
    <div
      className={classNames(
        'flex items-center justify-between gap-4 p-4 md:px-16',
        {
          'bg-dats-secondary-18': type === 'informative',
          'bg-dats-warning-bg': type === 'warning',
          'bg-dats-error-bg': type === 'error',
        },
      )}
      {...(blok && {...storyblokEditable(blok)})}
    >
      <ContentTag href={cta ?? ''} className="flex w-full items-center gap-4">
        {imageUrl ? (
          <Image
            src={imageUrl}
            alt="AlertIcon"
            width={20}
            height={20}
            className="size-4 shrink-0"
          />
        ) : (
          <Icon
            name={IconName.InfoCircle}
            width={16}
            height={16}
            className="shrink-0 text-dats-secondary-5"
          />
        )}
        <p className="text-sm">{message}</p>
        {cta ? (
          <Icon
            name={IconName.ArrowRight}
            width={16}
            height={16}
            className={iconColor}
          />
        ) : null}
      </ContentTag>
      {showClose ? (
        <button onClick={onClickClose}>
          <Icon
            name={IconName.Times}
            width={16}
            height={16}
            className={iconColor}
          />
        </button>
      ) : null}
    </div>
  )
}

export default Alert
