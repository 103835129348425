import React, {useEffect, useState} from 'react'
import {AlertStoryblok} from 'types/storyblok-types'
import Alert from 'components/shared/Alert'

const AlertContainer = ({blok}: {blok: AlertStoryblok}) => {
  const [displayBanner, setDisplayBanner] = useState<boolean>(false)

  const hideBanner = () => {
    localStorage.setItem(`alert_${blok._uid}`, 'hidden')
    setDisplayBanner(false)
  }

  useEffect(() => {
    const value = localStorage.getItem(`alert_${blok._uid}`)
    setDisplayBanner(value === null)
  }, [blok._uid])

  return (
    <>
      {displayBanner ? (
        <Alert
          message={blok.title}
          imageUrl={blok.icon?.filename}
          cta={blok.has_cta ? blok.cta_url?.cached_url : undefined}
          type={blok.type || 'informative'}
          showClose={blok.displayCloseButton ?? false}
          blok={blok}
          onClickClose={hideBanner}
        />
      ) : null}
    </>
  )
}
export default AlertContainer
