import {StoryblokComponent, getStoryblokApi} from '@storyblok/react'
import React, {useEffect, useState} from 'react'
import classNames from 'classnames'
import {pageTypes} from 'pages/[[...slug]]'
import {TranslatedSlug} from 'types/types'
import {MainMenuSlugAlternates} from 'mainMenuSlugTranslations'
import analytics from 'analytics'
import {useSession} from 'next-auth/react'
import {useCustomerAffiliateContext} from 'context/CustomerAffiliateProvider'
import {useRouter} from 'next/router'
import AuthError from 'components/AuthError'
import Maintenance from './Maintenance'
import {AlertStoryblok, MaintenancePageStoryblok} from 'types/storyblok-types'
import AlertContainer from './AlertContainer'
import Navigation from './Navigation'

type Layout = {
  children?: React.ReactNode
  mainMenuSlugAlternates: MainMenuSlugAlternates
  defaultSlug?: string
  translatedSlugs?: TranslatedSlug[]
} & Pick<pageTypes, 'footer' | 'mainMenu' | 'errorPage' | 'authPage'>

const Layout = ({
  children,
  mainMenu,
  mainMenuSlugAlternates,
  defaultSlug,
  translatedSlugs,
  footer,
  authPage,
}: Layout) => {
  const [isMainMenuVisible, setIsMainMenuVisible] = useState<boolean>(false)

  const {data, status} = useSession()
  const customerContext = useCustomerAffiliateContext()
  const router = useRouter()
  const [maintenance, setMaintenance] =
    useState<MaintenancePageStoryblok | null>(null)
  const [alert, setAlert] = useState<AlertStoryblok | null>(null)

  useEffect(() => {
    if (data && !data.extension_HasAccessToDATS24B2BMobilityPortal) {
      analytics.sendUnauthorizedPageView(
        'unauthorized',
        ['unauthorized'],
        200,
        customerContext.currentUser?.digitalServiceUserId,
        router.locale,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerContext.currentUser?.digitalServiceUserId])

  useEffect(() => {
    const storyblokApi = getStoryblokApi()

    const getAlert = async () => {
      let sbParams = {
        version: 'published' as const,
        resolve_links: 'story' as const,
        language: router.locale ?? 'nl',
      }

      return await storyblokApi.get(`cdn/stories/alerts/alert`, sbParams)
    }

    const getMaintenance = async () => {
      let sbParams = {
        version: 'published' as const,
        resolve_links: 'story' as const,
        language: router.locale ?? 'nl',
      }

      return await storyblokApi.get(
        `cdn/stories/singletons/maintenance`,
        sbParams,
      )
    }

    getAlert()
      .then(result => {
        if (result) {
          setAlert(result.data.story.content)
        }
        console.log('layout alert: ', result)
      })
      .catch(() => {})

    getMaintenance()
      .then(result => {
        if (result) {
          setMaintenance(result.data.story.content)
        }
        console.log('layout maintenance: ', result)
      })
      .catch(() => {})
  }, [router.locale])

  return status === 'authenticated' &&
    data?.extension_HasAccessToDATS24B2BMobilityPortal ? (
    <div className="bg-dats-accent-1">
      {alert ? <AlertContainer blok={alert} /> : null}
      <div className="flex w-full flex-col md:flex-row">
        <Navigation
          isMainMenuVisible={isMainMenuVisible}
          setIsMainMenuVisible={setIsMainMenuVisible}
          mainMenu={mainMenu}
          mainMenuSlugAlternates={mainMenuSlugAlternates}
          defaultSlug={defaultSlug ?? ''}
          translatedSlugs={translatedSlugs ?? []}
        />
        <div className="flex w-full flex-col justify-between md:overflow-hidden">
          {maintenance ? <Maintenance blok={maintenance} /> : null}
          <main
            className={classNames('px-6 pb-20 pt-12 md:p-12', {
              'hidden md:block': isMainMenuVisible,
            })}
          >
            {children}
          </main>
          <StoryblokComponent blok={footer.content} key={footer.uuid} />
        </div>
      </div>
    </div>
  ) : (
    <AuthError
      image={authPage.content.image}
      text={authPage.content.text}
      title={authPage.content.title}
    />
  )
}

export default Layout
